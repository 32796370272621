import apiClient from '@/services/axios';

const create = async (data) => {
    const res = await apiClient.post('/api/medical-records', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const update = async (id, data) => {
    const res = await apiClient.put(`/api/medical-records/${id}`, data)
    if (res) {
        return res
    } else {
        return null
    }

}

const getByPatientId = async (id) => {
    const res = await apiClient.get(`/api/medical-records/patient/${id}`)
    console.log("res", res)
    if (res) {
        return res.data
    } else {
        return null
    }
}

const getByMedRecordId = async (id) => {
    const res = await apiClient.get(`/api/medical-records/${id}`)
    if (res) {
        return res.data
    } else {
        return null
    }
}

const uploadAudio = async (id, data) => {
    const res = await apiClient.post(`/api/medical-records/${id}/audio`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    console.log("resss", res)
    if (res) {
        return res
    } else {
        return null
    }

}

export {
    create,
    update,
    getByPatientId,
    getByMedRecordId,
    uploadAudio,
}