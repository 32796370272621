<template>
  <div>
    <div :class="$style.head">
      <div :class="$style.headItem" class="mb-3 pr-3">
        <div :class="$style.headIcon" class="bg-light text-dark mr-3">
          <i class="fe fe-menu font-size-18" />
        </div>
        <div>
          <div class="text-uppercase text-muted text-nowrap">Jumlah Rekam Medis</div>
          <div class="font-weight-bold text-dark">
            {{ dataSource.length }}
          </div>
        </div>
      </div>
    </div>
    <a-divider />

    <div class="mb-4" style="z-index: 1">        
      <a-table :columns="columns" :dataSource="dataSource">
      </a-table>
    </div>
  </div>
</template>


<script>
import { Table } from 'ant-design-vue';
import { getByPatientId } from '@/services/axios/api/medicalRecord';

export default {
  components: {
    'a-table': Table,
  },
  async mounted() {
    const data = await getByPatientId(this.id_pasien);
    this.dataSource = data.medicalRecords.map((item, index) => {
      return {
        ...item,
        no: index + 1,
        formattedCreatedAt: this.changeDateFormat(item.createdAt),
        formattedUpdatedAt: this.changeDateFormat(item.updatedAt),
      };
    });
  },
  created(){
    this.id_pasien = this.$route.params.id
  },
  data() {
    return {
      id_pasien: '',
      columns: [
        {
          title: 'No', 
          dataIndex: 'no', 
          key: 'no',
        },
        {
          title: 'Tanggal Dibuat',
          dataIndex: 'formattedCreatedAt',
          key: 'formattedCreatedAt',
        },
        {
          title: 'Tanggal Diperbarui',
          dataIndex: 'formattedUpdatedAt',
          key: 'formattedUpdatedAt',
        },
        {
        title: 'Action',
        key: 'accessForm',
        customRender: (text, record) => {
          return this.$createElement('a', {
            attrs: {
              href: `#/formulir/${record.id}`,
            },
            domProps: {
              innerHTML: 'Buka Formulir',
            },
          });
        },
      },
      ],
      dataSource: [],
    };
  },
  methods: {
    changeDateFormat(tanggal){
      console.log('tanggal', tanggal)
      const dateObject = new Date(tanggal)
      console.log('dateObject', dateObject)
      const formattedDate = dateObject.toLocaleString('en-US', {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      return formattedDate
    },
  },
};

</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
