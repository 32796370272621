<template>
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div>
                <div style="font-size: 30px;">Data Medis</div>
                <div class="card">
                    <div class="card-header header-container">
                            <div>
                                <p>Nama : {{ this.nama }}</p>
                                <p>No. RM : {{ this.no_rm }}</p>
                                <p>Jenis Kelamin : {{ this.jenis_kelamin }}</p>
                                <p>Tanggal Lahir : {{ this.tanggal_lahir }}</p>
                                <p>Jenis Jaminan : {{ this.jenis_jaminan }}</p>
                            </div>
                            <a-button type="primary" @click="handleTambahButton">
                                + Tambah Data Medis
                            </a-button>
                    </div>
                    <div class="card-body">
                        <tabel-rekam-medis />
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import TabelRekamMedis from '@/components/tables/rekammedis'
import { getById } from '@/services/axios/api/pasien'
import { create } from '@/services/axios/api/medicalRecord';
import { notification } from 'ant-design-vue'
import router from '@/router'

export default{
    name: 'RekamMedis',
    components: {
        TabelRekamMedis,
    },
    data(){
        return{
            id_pasien: '',
            nama: 'nama',
            no_rm: 'no_rm',
            jenis_kelamin: 'jenis_kelamin',
            tanggal_lahir: 'tanggal_lahir',
            jenis_jaminan: 'jenis_jaminan',
        }
    },
    async mounted(){
        const data = await getById(this.id_pasien)
        this.nama = data.patient.name
        this.no_rm = data.patient.no_rm
        this.jenis_kelamin = data.patient.gender
        this.tanggal_lahir = this.changeDateFormat(data.patient.birthdate)
        this.jenis_jaminan = data.patient.insurance
    },
    created(){
        this.id_pasien = this.$route.params.id
    },
    methods: {
        changeDateFormat(tanggal){
            const dateObject = new Date(tanggal)
            const humanDateFormat = dateObject.toLocaleString()
            return humanDateFormat
        },
        async handleTambahButton(){
            const req = {
                "patient_id" : this.id_pasien,
            }
            console.log("req", req)
            const response = await create(req)
            console.log("response", response)
            const message = response.data.message
            const idMedRec = message.split(' ')[6]
            if (response.status === 201){
                notification.success({
                    message: 'Berhasil',
                    description: 'Data medis berhasil ditambahkan',
                })
                router.push(`/formulir/${idMedRec}`)
            }
        },
    },
}
</script>

<style scoped>
.header-container {
  display: flex;
  justify-content: space-between;
}
</style>